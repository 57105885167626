import { getKeyByValue } from '@powerednow/shared/modules/utilities/object';
import { PURCHASES, SUBSCRIPTION } from '@powerednow/shared/constants';

Ext.define('FieldServices.view.report.monthlyReports.detailed.grids.SubscriptionsMonthlyView', {
    extend: 'Ext.grid.Grid',

    xtype: 'subscriptionsMonthlyView',

    loadStoreWhenVisible: true,
    variableHeights: true,
    plugins: [{
        type: 'gridexporter',
    }],

    bind: {
        masked: '{monthlyDataLoading}',
    },
    store: 'MonthlyPurchaseReportData',
    itemConfig: {
        viewModel: true,
    },
    emptyText: true,
    showStatus: true,
    showQuantityFields: false,
    constructor(config) {
        config.columns = this.getColumnDefs();
        if (this.showStatus) {
            config.columns.push({
                text: 'Status',
                dataIndex: 'PurchaseAction.action',
                renderer: (value, record) => {
                    if(value === undefined || value === null) {
                      return 'CONSUMABLE';
                    }
                    const churnCorrected = record.get('PurchaseAction.is_reactivation') ? 2 : value;
                    return getKeyByValue(PURCHASES.LIFECYCLE_CATEGORY, churnCorrected);
                },
            });
        }
        this.callParent(arguments);
        this.store.on('datachanged', this.updateCountInTabTitle.bind(this));
    },
    updateCountInTabTitle() {
        const baseTitle = this.tab.config.title;
        const countText = this.store.isLoaded() ? this.store.count() : '-';
        this.tab.setTitle(`${baseTitle} (${countText})`);
    },

    getTeamSizeLabel(businessSize) {
      if(businessSize === 1) {
        return '1';
      }
      if( businessSize > 1 && businessSize < 7){
        return '2-6'
      }
      if( businessSize > 6 && businessSize < 15){
        return '7-14'
      }
      if( businessSize >= 15) {
        return '15+';
      }
      return 'N/A';
    },

  getColumnDefs() {
        return [
            {
              text: 'Company Id',
              dataIndex: 'company_id',
              renderer: (value, record) => record.data.company_id,
              exportRenderer: true
            },
            {
                text: 'White label',
                dataIndex: 'company_id',
                renderer: (value, record) => record.data.Company.WhiteLabel?.name || 'Powered Now',
                exportRenderer: true,
            },
            {
              text: 'Creation Date',
              dataIndex: 'creationDate',
              renderer: (value,record) => shared.Utils.Renderers.getDateRenderer('Y-m-d')(record.data.creationDate, 'C'),
              exportRenderer: true,
            },
            {
                text: 'Purchase Date',
                dataIndex: 'purchaseDate',
                renderer: (value,record) => shared.Utils.Renderers.getDateRenderer('Y-m-d')(record.data.purchaseDate, 'C'),
                exportRenderer: true,
            }, {
                text: 'Expires date',
                dataIndex: 'expiresDate',
                renderer: (value,record) => shared.Utils.Renderers.getDateRenderer('Y-m-d')(record.data.expiresDate, 'C'),
                exportRenderer: true,
            }, {
                text: 'Product',
                dataIndex: 'product',
                cellWrap: true,
                columns: [
                  { text: 'Tier:', renderer: (value,record) => record.data.PurchaseSetupIOS?.productName || record.data.PurchaseSetupAndroid.productName, exportRenderer:true},
                  { text: 'Subscription Length:', renderer: (value,record) => `${record.data.PurchaseSetupIOS?.days_before_expires || record.data.PurchaseSetupAndroid.days_before_expires} days`, exportRenderer:true},
                  { text: 'Actual Product:', style: {'font-size': '0.5em'}, renderer: (value,record) => record.data.product, exportRenderer:true},
                ],
            }, {
                text: 'Purchase',
                dataIndex: 'purchaseAmount',
                columns: [
                   ...(this.showQuantityFields ? [
                     {text: 'Quantity:', renderer: (value, record) => record.data.subscriptionCount + record.data.consumptionCount, exportRenderer:true },
                     {text: 'Subscriptions:', renderer: (value, record) => record.data.subscriptionCount, exportRenderer:true },
                     {text: 'Consumptions:', renderer: (value, record) => record.data.consumptionCount, exportRenderer:true },
                     {text: 'Promos:', renderer: (value, record) => record.data.promoCount, exportRenderer:true, bind: { hidden: '{excludePromotionalSubs}'}},
                     {text: 'Corrections:', renderer: (value, record) => record.data.correctionCount, exportRenderer:true, },
                   ] : []),
                  {
                    text: 'Amount',
                    renderer: (value, record) => record.data.purchaseAmount || 0,
                    exportRenderer: true
                  },
                  {
                    text: 'Original Price',
                    renderer: (value, record) => record.data.originalPrice || 0,
                    exportRenderer: true,
                  },
                  {
                    text: 'Ex tax',
                    renderer: (value, record) => ![SUBSCRIPTION.PURCHASE_ORIGIN_ID.ANDROID, SUBSCRIPTION.PURCHASE_ORIGIN_ID.ITUNES].includes(record.data.purchaseOrigin),
                    exportRenderer: true
                  },
                  {text: 'Currency', renderer: (value,record) => record.data.purchaseCurrency, exportRenderer: true},
                  {text: 'Origin:', renderer: (value,record) => getKeyByValue(SUBSCRIPTION.PURCHASE_ORIGIN_ID, record.data.purchaseOrigin), exportRenderer: true}
                ],

            }, {
                text: 'Company',
                dataIndex: 'company_id',
                columns: [
                  { text: 'Name', renderer: (value,record) => record.data.Company.companyName, exportRenderer: true},
                  { text: 'Business type', renderer: (value,record) => record.data.Company.BusinessTypes.type, exportRenderer: true},
                  { text: 'Team Size', renderer: (value,record) => this.getTeamSizeLabel(record.data.Company.teamSize), exportRenderer: true},
                  { text: 'Media Source', renderer: (value,record) => record.data.Company.mediaSource,  exportRenderer: true},
                  { text: 'Campaign Name', renderer: (value,record) => record.data.Company.campaignName, exportRenderer: true},
                  { text: 'Registration Date', renderer: (value,record) => shared.Utils.Renderers.getDateRenderer('Y-m-d')(record.data.Company.dt_created, 'C'), exportRenderer: true},
                ],
            }, {
                text: 'Main User',
                columns: [
                  { text: 'Name', renderer: (value,record) => `${record.data.Company.MainUser.firstName} ${record.data.Company.MainUser.lastName}`, exportRenderer: true},
                  { text: 'Email', renderer: (value,record) => record.data.Company.MainUser.mainEmail, exportRenderer: true},
                ],
            },
        ];
    },

});
