Ext.define('FieldServices.view.report.monthlyReports.detailed.grids.EndOfMonthSubComparison', {
  extend: 'FieldServices.view.report.monthlyReports.detailed.grids.SubscriptionsMonthlyView',
  xtype: 'endOfMonthSubComparison',
  showStatus: false,
  showQuantityFields: true,
  store: 'ActiveSubsAtMonthEndCompare',

  getColumnDefs(){
    const resultColumnDefs = [];
    const originalColumnDefs = this.callParent();
    resultColumnDefs.push(...originalColumnDefs.map(columnDef => this.getComparisonColumnDef(columnDef, 1)));
    resultColumnDefs.push(...originalColumnDefs.map(columnDef => this.getComparisonColumnDef(columnDef, 2)))
    return resultColumnDefs;
  },

  getComparisonColumnDef(columnDef, number){
    {
      const newColDef = {
        ...columnDef,
        text: `${columnDef.text}${number === 2 ? ' 2' : ''}`,
      }
      if(columnDef.renderer){
        newColDef.renderer = (value, record) => record.data[`values${number}`] ? columnDef.renderer(value, {data: record.data[`values${number}`]}) : '';
      }
      if(columnDef.columns){
        newColDef.columns = columnDef.columns.map(subColumnDef => ({
          ...subColumnDef,
          renderer :(value, record) => record.data[`values${number}`] ? subColumnDef.renderer(value, {data: record.data[`values${number}`]}) : '',
        }));
      }
      return newColDef
    }
  }
});
