import {FREE_SUBSCRIPTION_SETUPS} from '@powerednow/shared/constants/subscription';

Ext.define('FieldServices.view.report.monthlyReports.detailed.MonthlyReportsDetailedViewController', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.monthlyReportsDetailedViewController',
    xtype: 'monthlyReportsDetailedViewController',

    control: {
        monthlyReportsFilter: {
            changeFilter: 'loadMonthlyData',
            exportRequested: 'onExportRequested',
            promoExcludeChanged: 'refreshLocalData',
        },
        gridTabs: true,
    },

    config: {
        companyId: null,
    },

    onExportRequested() {
        const activeTab = this.getGridTabs().getActiveItem();
        const reportName = activeTab.tab.config.title.replaceAll(' ', '_');
        const reportDate = this.getMonthlyReportsFilter().getDisplayValue(activeTab.config.itemId === 'endOfMonthSubComparison');
        activeTab.saveDocumentAs({ fileName: `${reportName} ${reportDate}.xlsx` });
    },

    async loadMonthlyData({ filterData }) {
        this.getViewModel().setData({ monthlyDataLoading: true });
        try {
            await this.loadSubscriptionsOfMonth(filterData);
            await this.loadActiveSubsForMonth(filterData);
            await this.loadNewRegistrations(filterData);

            this.getViewModel().setData({ monthlyDataLoading: false });
            this.showReportSourceDetails();
        } catch (error) {
            this.getViewModel().setData({ monthlyDataLoading: false });
            handleClientError(error);
        }
    },

    async loadNewRegistrations(filterData) {
      const apiResponse = await FieldServices.app.callAPI(
        {
          url: `api/admindata/purchases/getNewRegistrationsForMonth/0`,
          method: 'GET',
          params: { filter: Ext.encode(filterData) },
          headers: {},
        },
      );

      const data = apiResponse || [];
      Ext.getStore('NewRegistrationsInMonth').setData(data);
    },

     async loadSubscriptionsOfMonth(filterData) {
       const apiResponse = await FieldServices.app.callAPI(
         {
           url: `api/admindata/purchases/getSubscriptionsForMonth/0`,
           method: 'GET',
           params: { filter: Ext.encode(filterData) },
           headers: {},
         },
       );

       this.getViewModel().setData({rawPurchasesOfMonth: apiResponse || []});
       this.loadSubscriptionStores();
     },

    async loadActiveSubsForMonth(filterData) {
      const baseFilterData = [];
      const comparisonFilterData = [];
      filterData.forEach(filterObj => {
        switch(filterObj.property){
          case 'month':
            baseFilterData.push(filterObj);
            break;
          case 'comparisonMonth':
            comparisonFilterData.push(filterObj);
            break;
          default:
            baseFilterData.push(filterObj);
            comparisonFilterData.push(filterObj);
        }
      });
      const apiResponse = await FieldServices.app.callAPI(
        {
          url: `api/admindata/purchases/getSubscribersForMonth/0`,
          method: 'GET',
          params: { filter: Ext.encode(baseFilterData) },
          headers: {},
        },
      );
      const comparisonResponse = await FieldServices.app.callAPI(
        {
          url: `api/admindata/purchases/getSubscribersForMonth/0`,
          method: 'GET',
          params: { filter: Ext.encode(comparisonFilterData) },
          headers: {},
        },
      );

      this.getViewModel().setData({rawActiveSubsOfMonth: apiResponse || [], rawActiveSubsOfComparisonMonth: comparisonResponse || []});
      this.loadActiveSubsStore();
    },

    loadSubscriptionStores() {
      const { rawPurchasesOfMonth } = this.getViewModel().getData();
      const monthlyPurchaseData = [];
      const newPurchaseData = [];
      const monthlyPurchaseStats = { subRecords: {paid : 0, promo: 0}, consumptionRecords: {paid: 0, promo: 0}, companies: 0, promoCompanies: 0 };
      const newPurchaseStats = { subRecords: {paid : 0, promo: 0}, consumptionRecords: {paid: 0, promo: 0}, companies: 0, promoCompanies: 0 };
      const { promoOriginIds, correctionOriginIds, excludePromotionalSubs } = this.getPromoFilteringData();
      Object.values(rawPurchasesOfMonth).forEach(({subscriptions, consumptions}) => {
        monthlyPurchaseStats.companies += 1;
        let hasNonPromoSub = false;
        [...subscriptions, ...consumptions].forEach(purchaseRec => {
          const isPromo = promoOriginIds.includes(purchaseRec.purchaseOrigin);
          hasNonPromoSub = hasNonPromoSub || !isPromo;
          const recordCategoryKey = purchaseRec.PurchaseAction ? 'subRecords' : 'consumptionRecords';
          const countPropKey = isPromo ? 'promo' : 'paid';
          monthlyPurchaseStats[recordCategoryKey][countPropKey] += 1;
          if(!isPromo || !excludePromotionalSubs){
            monthlyPurchaseData.push({...purchaseRec});
          }
        });
        if(!hasNonPromoSub) {
          monthlyPurchaseStats.promoCompanies += 1;
        }

        const newSub = subscriptions
          .find(subRecord => subRecord.PurchaseAction.action === 0);
        if(newSub){
          let isPaid = !promoOriginIds.includes(newSub.purchaseOrigin);
          newPurchaseStats.companies += 1;
          newPurchaseStats.subRecords[isPaid ? 'paid' : 'promo'] += 1;
          newSub.subscriptionCount = 1;
          newSub.consumptionCount = 0;
          newSub.promoCount = 0;
          newSub.correctionCount = 0;
          if(!isPaid){
            newSub.promoCount += 1;
          }
          consumptions.forEach(consumptionRec => {
            const isPromo = promoOriginIds.includes(consumptionRec.purchaseOrigin);
            const isCorrection = correctionOriginIds.includes(consumptionRec.purchaseOrigin);
            if(isCorrection) {
              newSub.correctionCount += 1;
            }
            if(isPromo) {
              newSub.promoCount += 1;
            }
            isPaid = isPaid || isPromo;
            const countPropKey = isPromo ? 'promo' : 'paid';
            newPurchaseStats.consumptionRecords[countPropKey] += 1;
            if(!isPromo || !excludePromotionalSubs){
              newSub.consumptionCount += 1;
              newSub.purchaseAmount += consumptionRec.purchaseAmount;
              newSub.originalPrice += consumptionRec.originalPrice;
            }
          });
          if(!isPaid){
            newPurchaseStats.promoCompanies += 1;
          }
          if(isPaid || !excludePromotionalSubs){
            newPurchaseData.push(newSub);
          }
        }
      });
      Ext.getStore('MonthlyPurchaseReportData').setData(monthlyPurchaseData);
      Ext.getStore('NewMonthlyReportedPurchases').setData(newPurchaseData);
      this.getViewModel().setData({monthlyPurchaseStats,newPurchaseStats});
    },

  refreshLocalData(){
      this.getViewModel().setData({ monthlyDataLoading: true });
      this.loadActiveSubsStore();
      this.loadSubscriptionStores();
      this.getViewModel().setData({ monthlyDataLoading: false });
  },

    loadActiveSubsStore(){
      const { rawActiveSubsOfMonth, rawActiveSubsOfComparisonMonth } = this.getViewModel().getData();
      const {activeSubsData, activeSubStats} = this.processActiveSubData(rawActiveSubsOfMonth);
      const {activeSubsData: comparisonSubsData} = this.processActiveSubData(rawActiveSubsOfComparisonMonth);
      const comparisonMap = {};
      comparisonSubsData.forEach(data=> {
        comparisonMap[data.company_id] = {values1: data}
      })
      activeSubsData.forEach(data=> {
        comparisonMap[data.company_id] = {...(comparisonMap[data.company_id] || {}), values2: data}
      })
      Ext.getStore('ActiveSubsAtMonthEnd').setData(activeSubsData);
      Ext.getStore('ActiveSubsAtMonthEndCompare').setData(Object.values(comparisonMap));
      this.getViewModel().setData({activeSubStats});
    },

    processActiveSubData(rawData){
      const activeSubsData = [];
      const activeSubStats = { subRecords: {paid : 0, promo: 0}, consumptionRecords: {paid: 0, promo: 0}, companies: 0, promoCompanies: 0 };
      const { promoOriginIds, correctionOriginIds, excludePromotionalSubs } = this.getPromoFilteringData();
      Object.values(rawData).forEach(({subscriptions, consumptions}) => {
        if(subscriptions.length){
          let hasNonPromoRecords = false;
          let sumPurchaseAmount = 0;
          let sumOriginalPrice = 0;
          let subscriptionCount = 0 ;
          let consumptionCount = 0;
          let promoCount = 0;
          let correctionCount = 0;
          [...subscriptions, ...consumptions].forEach(purchaseRec => {
            const isPromo = promoOriginIds.includes(purchaseRec.purchaseOrigin);
            const isCorrection = correctionOriginIds.includes(purchaseRec.purchaseOrigin);
            promoCount += isPromo ? 1 : 0;
            correctionCount += isCorrection ? 1 : 0;
            hasNonPromoRecords = hasNonPromoRecords || !isPromo;
            const isSub = purchaseRec.PurchaseAction;
            const recordCategoryKey = isSub ? 'subRecords' : 'consumptionRecords';
            const countPropKey = isPromo ? 'promo' : 'paid';
            activeSubStats[recordCategoryKey][countPropKey] += 1;
            if(!isPromo || !excludePromotionalSubs) {
              subscriptionCount += isSub ? 1 : 0;
              consumptionCount += isSub ? 0 : 1;
              const { purchaseAmount, originalPrice } = purchaseRec;
              sumPurchaseAmount += purchaseAmount;
              sumOriginalPrice += originalPrice;
            }
          });
          if(!excludePromotionalSubs || hasNonPromoRecords){
            activeSubStats.companies += 1;
          }
          if(!hasNonPromoRecords){
            activeSubStats.promoCompanies += 1;
          }
          const usedSubRecords = subscriptions
            .filter(subRecord => !promoOriginIds.includes(subRecord.purchaseOrigin) || !excludePromotionalSubs);
          if(usedSubRecords.length){
            const activeSubRecord = usedSubRecords[0];
            const creationDate = usedSubRecords.sort((subRecordA, subRecordB) => subRecordA.creationDate - subRecordB.creationDate)[0].creationDate;
            activeSubsData.push({
              subscriptionCount,
              consumptionCount,
              promoCount,
              correctionCount,
              purchaseAmount: sumPurchaseAmount,
              originalPrice: sumOriginalPrice,
              creationDate,
              ...activeSubRecord
            });
          }
        }

      });
      return {activeSubStats, activeSubsData}
    },

    getPromoFilteringData(){
      const promoOriginIds = [4];
      const correctionOriginIds = [];
      Object.values(FREE_SUBSCRIPTION_SETUPS).forEach(subscriptionConfig => {
        if(!subscriptionConfig.INCLUDE_IN_REPORTS){
          promoOriginIds.push(subscriptionConfig.PURCHASE_ORIGIN_ID)
        } else {
          correctionOriginIds.push(subscriptionConfig.PURCHASE_ORIGIN_ID);
        }
      });
      const { excludePromotionalSubs } = this.getViewModel().getData();
      return {promoOriginIds, correctionOriginIds, excludePromotionalSubs};
    },


    showReportSourceDetails() {
      const {monthlyPurchaseStats, newPurchaseStats, activeSubStats} = this.getViewModel().getData();

      const dialog = Ext.create({
        xtype: 'dialog',
        title: 'Report Data Usage Details',

        html: `
          <table>
            <tr>
                <td>${this.generateReportStatHtml('All Subscriptions', monthlyPurchaseStats)}</td>
                <td>${this.generateReportStatHtml('New Subscribers', newPurchaseStats)}</td>
                <td>${this.generateReportStatHtml('Active Subscribers at the end of month',activeSubStats)}</td>
            </tr>
          </table>
          <h3>Notes:</h3>
          <ul>
            <li><i>(Promotional records are purchases with promotional purchase origins, the 'Total' values include these)</i></li>
            <li><i>(Companies are considered promotional if they <b>only</b> have promotional subscriptions and consumptions, the 'Total' values include these)</i></li>
            <li><i>(In the active subscriptions at the end of month report, companies that don't have an active base subscription are excluded, even if they have active extra users)</i></li>
          </ul>

        `,

        buttons: {
          ok: function () {
            dialog.destroy();
          }
        }
      });

      dialog.show();
    },

    generateReportStatHtml(title, dataObj) {
      const {subRecords, consumptionRecords, companies, promoCompanies} = dataObj;
      const allSubs = subRecords.paid + subRecords.promo;
      const allConsumptions = consumptionRecords.paid + consumptionRecords.promo;
      return `
        <h3>${title}:</h3>
        <table>
            <tr><td>Records in range:</td><td><small>(All Subscriptions and Extra User purchases)</small></td></tr>
            <tr><td><ul><li>Total: ${allSubs + allConsumptions}</li><li>Promotional: ${subRecords.promo + consumptionRecords.promo}</li></ul></td></tr>
            <tr><td>Base Subscriptions reported</td><td><small>(Number of Subscription records)</small></td></tr>
            <tr><td><ul><li>Total: ${allSubs}</li><li>Promotional: ${subRecords.promo}</li></ul></td></tr>
            <tr><td>Extra Subscriptions reported</td><td><small>(Number of Extra User purchase records)</small></td></tr>
            <tr><td><ul><li>Total: ${allConsumptions}</li><li>Promotional: ${consumptionRecords.promo}</li></ul></td></tr>
            <tr><td> Companies reported on</td><td><small>(Number of distinct companies with records in range )</small></td></tr>
            <tr><td><ul><li>Total: ${companies}</li><li>Promotional: ${promoCompanies}</li></ul></td></tr>
        </table>
      `
    }

});
