Ext.define('FieldServices.view.widgets.filters.WhiteLabelFilter', {
    extend: 'Ext.Container',
    nameHolder: true,
    xtype: 'whiteLabelFilter',
    alias: 'widget.whiteLabelFilter',
    layout: 'vbox',
    cls: ['whiteLabelFilter', 'x-field'],
    viewModel: {
        stores: {
            whitelabelStore: {
                type: 'WhiteLabel',
                autoLoad: true,
                filters: [{
                    property: 'whitelabel_status',
                    operator: '!=',
                    value: 0,
                }],
            },
        },
        formulas: {
            whiteLabelOptions: {
                bind: {
                    bindTo: {
                        store: '{whitelabelStore}',
                        loadCount: '{whitelabelStore.loadCount}',
                    },
                    deep: true,
                },
                get({ store }) {
                    if (!store.isLoaded() || store.loading) {
                        return [{ id: 1, name: 'Powered Now' }];
                    }
                    if (this.getView().getIncludeObsolete()) {
                        this.getView().getViewModel().getStore('whitelabelStore').removeFilter('whitelabel_status');
                    }

                    const storeOptions = store.getRecords()
                        .filter(record => this.getView().getIncludeTest() || record.get('whitelabel_status') !== 2)
                        .map(record => ({ id: record.get('id'), name: record.get('name') }));
                    return [{ id: -1, name: 'All' }, ...storeOptions];
                },
            },
        },
    },
    config: {
        includeTest: true,
        includeObsolete: false,
        labelAlign: 'top',
    },

    items: [
        {
            xtype: 'selectfield',
            itemId: 'filterSelector',
            reference: 'filterSelector',
            fieldName: 'whitelabel_id',
            tableName: 'Company',
            name: 'whitelabel_id',
            label: 'White Label:',
            labelAlign: 'top',
            labelWidth: 'auto',
            autoHideInputMask: true,
            value: -1,
            bind: {
                options: '{whiteLabelOptions}',
                isFilter: '{filterSelector.value !== -1}',
            },
            displayField: 'name',
            filterOperator: '=',
            valueField: 'id',
        },

    ],
    constructor(...args) {
        this.callParent(args);
        this.setHidden(true);
        AuthManager.userHasRole(CONSTANTS.ROLES.PERMISSIONS.CAN_ACCESS_ANY_WHITELABEL).then(hasRole => {
            if (hasRole) {
                this.setHidden(false);
            } else {
                this.items.get('filterSelector').setValue(AuthManager.getCurrentAdminData().companyWhiteLabel);
            }
        });
    },

    setLabelAlign(value) {
        const selector = this.items.get('filterSelector');
        if (selector) {
            selector.setLabelAlign(value);
        } else {
            this.on('painted', this.setLabelAlign, this, { single: true, args: [value] });
        }
    },

    getFilters() {
        return this.items.get('filterSelector').getFilters();
    },

    getValue() {
        const selectorValue = this.items.get('filterSelector').getValue();
        return selectorValue > 0 ? selectorValue.toString() : undefined;
    },

    setSelection(whiteLabelId) {
        const selector = this.items.get('filterSelector');
        const recordToSelect = selector.findRecordByValue(whiteLabelId);
        selector.setSelection(recordToSelect);
    },
});
